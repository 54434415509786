import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { camelCaseToInitialCapital } from '@lib/Utils';
import { Input, DatePicker } from '@lib/components/v2/Form';
import { isProofOfAgeDocument } from '@lib/constants/documents';
import classes from './VerifyAdditionalDetails.style.module.scss';
import FormModel from './VerifyAdditionalDetails.form';

const VerifyAdditionalDetails = ({
  pageTitle = 'Are your ID details correct?',
  onChange,
  idTypeTitle,
  useIdNumber,
  data,
  isSubmiting
}) => {
  const { VOI_FLOW_V2_VERIFY_DETAILS_DESC = '', FLOW_V2_DATEPICKER_FORMAT } = process.env;

  const idType = data.cardType || '';

  const NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = VOI_FLOW_V2_VERIFY_DETAILS_DESC.replace(
    '{idType}',
    idTypeTitle
  );

  let dataValue = data;

  if (
    idType &&
    (idType.match(/PASSPORT/i) || idType.match(/IMMI/i) || isProofOfAgeDocument(idType))
  ) {
    dataValue = {
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      dateOfBirth: data.dateOfBirth,
      idNumber: data.idNumber,
      expiryDate: data.expiryDate
    };
  }
  const excluded = [
    'asf',
    'cardType',
    'addressApiCalls',
    'checkConfirm',
    'expiryDate',
    'countryOfIssue',
    'countryCode',
    'engineCountryCode',
    'categories0',
    'expiryDate0',
    'issueDate0',
    'issuer',
    'evidenceNo',
    'documentId',
    'addressData'
  ];
  if (
    idType &&
    (idType.match(/PASSPORT/i) ||
      idType.match(/MEDICARE/i) ||
      idType.match(/IMMI/i) ||
      isProofOfAgeDocument(idType))
  ) {
    const index = excluded.indexOf('expiryDate');
    if (index > -1) {
      excluded.splice(index, 1);
    }
  }

  const datePickerProps = {
    displayedDateFormat: FLOW_V2_DATEPICKER_FORMAT
  };
  if (idType && idType.match(/MEDICARE/i)) {
    excluded.push('dateOfBirth');
    datePickerProps.displayedDateFormat = 'MM-YYYY';
  }

  if (idType && idType.match(/IMMI/i)) {
    excluded.push('nationalityCode');
    excluded.push('gender');
    excluded.push('givenName');
    excluded.push('validation');
    excluded.push('fullName');
  }

  const fields = [];
  Object.keys(dataValue).forEach((field) => {
    if (!excluded.includes(field)) {
      if (field.match(/date/i)) {
        fields.push({
          id: field,
          label: camelCaseToInitialCapital(field),
          value: dataValue[field],
          date: true
        });
      } else {
        let type = 'text';
        let label = field;
        if (field === 'medicareRefNumber') {
          type = 'number';
          label = 'medicareReference';
        } else if (field === 'cardNumber' && idType.match(/CITIZEN/i)) {
          label = 'certificateNumber';
        }

        fields.push({
          id: field,
          label: camelCaseToInitialCapital(label),
          value: dataValue[field],
          type
        });
      }
    }
  });

  /**
   * Generate form fields.
   */
  const generateFields = fields.map((field, i) => {
    const { id, label, value, date } = field;
    const labelDataTestId = `details-field-${i}-lbl`;
    const dataTestId = `details-field-${i}`;

    const nId = useIdNumber && id === 'passportNumber' ? 'idNumber' : id;

    let validationRule = FormModel[nId] || { [nId]: {} };
    if (idType) {
      let required = [];
      if (idType.match(/MEDICARE/i)) {
        required = ['expiryDate', 'medicareRefNumber'];
      } else if (idType.match(/CITIZEN/i)) {
        required = ['cardNumber'];
      }
      if (required.includes(id)) {
        const fieldName = camelCaseToInitialCapital(label);
        validationRule = {
          [id]: {
            isRequired: `Please enter ${fieldName}`
          }
        };
      }
    }

    // if its date
    if (date) {
      const restProps = {
        id,
        label,
        value
      };
      return (
        <div key={id} className={classNames('', classes.userDetailRow)}>
          <span data-testid={labelDataTestId}>{field.label}</span>
          <Validation config={validationRule} initialValues={{ [id]: value }}>
            {({ errors: formErrors, setField }) => {
              return (
                <DatePicker
                  key={id}
                  {...datePickerProps}
                  className={classNames(classes.input)}
                  hasError={formErrors[id]}
                  onChange={(value) => {
                    onChange(id, value);
                    setField({ [id]: value });
                  }}
                  {...restProps}
                  dataTestId={`${dataTestId}-datePicker`}
                  disabled={isSubmiting}
                />
              );
            }}
          </Validation>
        </div>
      );
    }

    return (
      <div key={id} className={classNames('', classes.userDetailRow)}>
        <span data-testid={labelDataTestId}>{field.label}</span>
        <Validation config={validationRule} initialValues={{ [id]: value }}>
          {({ dirty, errors: formErrors, setField }) => {
            return (
              <Input
                key={id}
                placeholder={field.label}
                paddingLeft30
                className={classNames(classes.input)}
                hasError={dirty[id] ? formErrors[id] : null}
                onChange={(value) => {
                  onChange(id, value);
                  setField({ [id]: value });
                }}
                {...field}
                dataTestId={`${dataTestId}-txt`}
                disabled={isSubmiting}
              />
            );
          }}
        </Validation>
      </div>
    );
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.heading} data-testid="details-heading">
        {pageTitle}
      </div>
      <div className={classes.description}>{NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING}</div>
      <div className={classes.container}>{generateFields}</div>
    </div>
  );
};

VerifyAdditionalDetails.propTypes = {
  onChange: PropTypes.func,
  errors: PropTypes.object,
  data: PropTypes.object,
  pageTitle: PropTypes.string,
  idTypeTitle: PropTypes.string,
  useIdNumber: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyAdditionalDetails.defaultProps = {
  errors: {},
  data: {},
  onChange: () => {},
  isSubmiting: false
};

export default VerifyAdditionalDetails;
