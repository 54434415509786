import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormValidation } from 'calidation';

import { Input, DatePicker, Select, CountrySelect } from '@lib/components/v2/Form';
import Page from '@lib/components/v2/Page';
import { TitlePage } from '@lib/components/v2/TitlePage';
import Loading from '@FLOW_V2_FLOW/components/Contents/Datacheck/Datacheck.Loader'; // TODO: do not use from flowv2, move or create error component
import {
  ConfirmAge,
  UnderEligibleAge,
  ExpiredID,
  ExpiredIDBy2Years
} from '@FLOW_V2_FLOW/containers/VerifyDetails/VerifyDetails.errors'; // TODO: do not use from flowv2, move or create error component
import { localizedString } from '@languages';
import { checkDataOnlyDetails } from '@services/dataCheckAPI';

import { DOCUMENTS } from '@lib/constants/documents';
import { INPUT_TYPES } from '@lib/constants/inputTypes';

import { getAge } from '@lib/Utils';
import { isAgeEligible } from '@lib/utils/ageEligibility';
import { isCardNumberFieldVisible } from '@lib/utils/cardNumberField';
import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import {
  isPassportExpiredBy2Years,
  isEligibleForPassportExpiredLogic,
  isDocumentExpired
} from '@lib/utils/checkExpiredDocument';

import { Error500 } from '@FLOW_V2_FLOW/errors'; // TODO: do not use from flowv2, move or create error component
import {
  fieldsValidationConfig,
  commonFields,
  additionalFieldsPerCardType,
  optionalCountryField
} from './fieldsConfig';

import classes from './ManualEntryFormScreen.style.module.scss';

const LabelInputWrapper = ({ fieldLabel, children }) => {
  return (
    <div className={classes.formInputRow}>
      <span>{fieldLabel}</span>
      <span>{children}</span>
    </div>
  );
};

LabelInputWrapper.propTypes = {
  fieldLabel: PropTypes.string,
  children: PropTypes.node
};

const ManualEntryFormScreen = ({
  appConfig,
  acceptedCountries,
  documentCardType,
  includeCountry = false,
  onCloseManualEntryFormScreen = () => {},
  onDataCheckReady = () => {},
  initialData = {}
}) => {
  const { ENABLE_CONFIRM_AGE = true, ELIGIBLE_AGE = 18, FLOW_V2_DATEPICKER_FORMAT } = process.env;

  const DEFAULT_COUNTRY_CODE = 'AU';

  const errors = {
    UNDER_ELIGIBLE_AGE: 'UNDER_ELIGIBLE_AGE',
    EXPIRED_DOCUMENT: 'EXPIRED_DOCUMENT',
    EXPIRED_PASSPORT_BY_2_YEARS: 'EXPIRED_PASSPORT_BY_2_YEARS',
    UNEXPECTED_ERROR: 'UNEXPECTED_ERROR'
  };

  const [error, setError] = useState(null);
  const [showAgeConfirmation, setShowAgeConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fields, setFields] = useState(() => {
    let countryField = {};

    if (includeCountry) {
      countryField = { ...optionalCountryField };
    }

    const dataTemplate = {
      ...commonFields,
      ...(additionalFieldsPerCardType[documentCardType]
        ? additionalFieldsPerCardType[documentCardType]
        : {}),
      ...countryField
    };

    /* eslint-disable no-param-reassign */
    return Object.keys(dataTemplate).reduce((result, key) => {
      result[key] = dataTemplate[key];
      if (initialData[key]) {
        result[key].value = initialData[key].value;
      }
      return result;
    }, {});
    /* eslint-disable no-param-reassign */
  });

  const countryCode =
    fields.countryCode && fields.countryCode.value
      ? fields.countryCode.value.value || DEFAULT_COUNTRY_CODE
      : DEFAULT_COUNTRY_CODE;

  const updateFields = (fieldKey, value) => {
    const fieldsToUpdate = {
      ...fields,
      [fieldKey]: {
        ...fields[fieldKey],
        value
      }
    };

    if (documentCardType === DOCUMENTS.DRIVER_LICENCE.cardType) {
      if (
        isCardNumberFieldVisible({
          idType: documentCardType,
          countryOfIssue: getCountryLabelFromIso2Code(DEFAULT_COUNTRY_CODE)
        })
      ) {
        if (!Object.prototype.hasOwnProperty.call(fieldsToUpdate, 'documentNumber')) {
          fieldsToUpdate.documentNumber = {
            ...additionalFieldsPerCardType[documentCardType].documentNumber
          };
        }
      } else if (Object.prototype.hasOwnProperty.call(fieldsToUpdate, 'documentNumber')) {
        delete fieldsToUpdate.documentNumber;
      }
    }

    setFields(fieldsToUpdate);
  };

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onCloseManualEntryFormScreen
    },
    {
      label: localizedString('enterMyDetails'),
      type: 'submit',
      disabled: isLoading
    }
  ];

  const confirmAgeButtons = [
    {
      label: localizedString('no'),
      variant: 'transparent',
      onClick: () => setShowAgeConfirmation(false)
    },
    {
      label: localizedString('yes'),
      onClick: () => {
        setError(errors.UNDER_ELIGIBLE_AGE);
      }
    }
  ];

  const expiredIdButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => {
        setError(null);
      }
    },
    {
      label: localizedString('recaptureDocument'),
      onClick: () => {
        onCloseManualEntryFormScreen({ exitCompletely: true });
      }
    }
  ];

  const prepareFieldsToSentToServer = (fields) => {
    const transToken = document.querySelector('body').getAttribute('data-id');

    const payload = Object.keys(fields).reduce((result, fieldKey) => {
      const field = fields[fieldKey];
      if (field.type === INPUT_TYPES.DROPDOWN) {
        result[fieldKey] = field.value.value;
      } else if (field.type === INPUT_TYPES.DATE) {
        const inputFormat = 'DD-MM-YYYY';
        const outputFormat = 'DD/MM/YYYY';
        result[fieldKey] = moment(field.value, inputFormat).format(outputFormat);
      } else {
        result[fieldKey] = field.value;
      }
      return result;
    }, {});

    if (!includeCountry && documentCardType === DOCUMENTS.PASSPORT.cardType) {
      payload.countryCode = DEFAULT_COUNTRY_CODE;
    }

    return {
      ...payload,
      transactionId: transToken,
      documentType: documentCardType
    };
  };

  const hasDocumentExpired = (fields = {}) => {
    const expiryDate = fields.expiryDate.value;
    const cardType = documentCardType;

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    return isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;
  };

  const handleSubmit = async ({ errors }) => {
    const fieldsToForceRequiredCheck = ['birthDate', 'documentState', 'countryCode', 'expiryDate'];

    const isValid = Object.keys(fields).reduce((isValidAcc, fieldKey) => {
      return (
        isValidAcc &&
        (fieldsToForceRequiredCheck.includes(fieldKey)
          ? !!fields[fieldKey].value
          : !errors[fieldKey])
      );
    }, true);

    if (isValid) {
      const age = getAge(fields.birthDate.value, true);
      if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, ELIGIBLE_AGE)) {
        setShowAgeConfirmation(true);
        return;
      }

      if (hasDocumentExpired(fields)) {
        const expiredError = isEligibleForPassportExpiredLogic(
          appConfig,
          documentCardType,
          countryCode
        )
          ? errors.EXPIRED_PASSPORT_BY_2_YEARS
          : errors.EXPIRED_DOCUMENT;
        setError(expiredError);
        return;
      }

      try {
        setIsLoading(true);
        const response = await checkDataOnlyDetails(prepareFieldsToSentToServer(fields));
        onDataCheckReady({ fields, hasFailed: response.retryDataCheck });
        setError(null);
      } catch (error) {
        setError(errors.UnexpectedError);
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  if (error) {
    if (error === errors.UNDER_ELIGIBLE_AGE) {
      return <UnderEligibleAge />;
    }
    if (error === errors.EXPIRED_DOCUMENT) {
      return <ExpiredID buttons={expiredIdButtons} />;
    }
    if (error === errors.EXPIRED_PASSPORT_BY_2_YEARS) {
      return <ExpiredIDBy2Years buttons={expiredIdButtons} />;
    }
    return <Error500 />;
  }

  if (isLoading) {
    return (
      <Page title={localizedString('dataCheck')}>
        <Loading />
      </Page>
    );
  }

  return (
    <React.Fragment>
      {showAgeConfirmation && <ConfirmAge buttons={confirmAgeButtons} />}
      <FormValidation onSubmit={handleSubmit} config={fieldsValidationConfig}>
        {({ errors, submitted }) => {
          return (
            <Page buttons={footerButtons}>
              <React.Fragment>
                <TitlePage text={localizedString('enterYourIDDetails')} />
                <div className={classes.formContainer}>
                  {Object.keys(fields).map((fieldKey) => renderField(fieldKey, errors, submitted))}
                </div>
              </React.Fragment>
            </Page>
          );
        }}
      </FormValidation>
    </React.Fragment>
  );

  function renderField(fieldKey, errors, submitted) {
    const field = fields[fieldKey];

    if (field.type === INPUT_TYPES.TEXT) {
      return (
        <LabelInputWrapper key={fieldKey} fieldLabel={field.label}>
          <Input
            id={fieldKey}
            placeholder={field.label}
            paddingLeft30
            hasError={submitted && errors[fieldKey]}
            onChange={(value) => updateFields(fieldKey, value)}
            value={field.value}
            type={field.type}
          />
        </LabelInputWrapper>
      );
    }
    if (field.type === INPUT_TYPES.DATE) {
      return (
        <LabelInputWrapper key={fieldKey} fieldLabel={field.label}>
          <DatePicker
            id={fieldKey}
            name={fieldKey}
            hasError={submitted && !field.value ? errors[fieldKey] : null}
            onChange={(value) => updateFields(fieldKey, value)}
            value={field.value}
            displayedDateFormat={FLOW_V2_DATEPICKER_FORMAT}
          />
        </LabelInputWrapper>
      );
    }
    if (field.type === INPUT_TYPES.DROPDOWN && !field.isCountryList) {
      return (
        <LabelInputWrapper key={fieldKey} fieldLabel={field.label}>
          <Select
            id={fieldKey}
            name={fieldKey}
            borderBottomOnly
            paddingLeft30
            placeholder={field.label}
            hasError={submitted && !field.value ? errors[fieldKey] : null}
            options={field.options || []}
            onChange={(value) => updateFields(fieldKey, value)}
            value={field.value}
          />
        </LabelInputWrapper>
      );
    }
    if (field.type === INPUT_TYPES.DROPDOWN && field.isCountryList) {
      return (
        <LabelInputWrapper key={fieldKey} fieldLabel={field.label}>
          <CountrySelect
            paddingLeft30
            borderBottomOnly
            backgroundColor="#FFFFFF"
            filter={acceptedCountries}
            value={field.value}
            hasError={submitted && !field.value ? errors[fieldKey] : null}
            onChange={(value) => updateFields(fieldKey, value)}
          />
        </LabelInputWrapper>
      );
    }

    return null;
  }
};

ManualEntryFormScreen.propTypes = {
  appConfig: PropTypes.object,
  acceptedCountries: PropTypes.array,
  documentCardType: PropTypes.string,
  includeCountry: PropTypes.bool,
  onCloseManualEntryFormScreen: PropTypes.func,
  onDataCheckReady: PropTypes.func,
  initialData: PropTypes.object
};

const mapStateToProps = ({ appConfig }) => {
  const engine4Config = appConfig.engine4 ? appConfig.engine4.FLOW_V2 : null;

  return {
    appConfig,
    acceptedCountries: engine4Config ? engine4Config.acceptedCountries : null
  };
};

export default connect(mapStateToProps, null)(ManualEntryFormScreen);
