import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import isArray from 'lodash/isArray';
import classes from './AddressFinder.style.module.scss';

export const AddressList = ({
  loading = false,
  data = [],
  onSelect,
  hasError,
  isManualOptionOn,
  onClickManualOption
}) => {
  if (loading) {
    return (
      <ul className={classes.addresses}>
        <li className={classes.loading}>{localizedString('loading')}</li>
      </ul>
    );
  }

  return (
    <ul className={classes.addresses}>
      {hasError && (
        <li className={classes.error}>
          {localizedString('addressNotFound')}
          {isManualOptionOn && (
            <>
              <br />
              {localizedString('orUpperCase')} "{localizedString('enterManually')}"
            </>
          )}
        </li>
      )}
      {!hasError &&
        isArray(data) &&
        data.map(({ text, globalAddressKey = null }) => {
          return (
            <li onClick={() => onSelect(text, globalAddressKey)} key={text}>
              <span>
                <img width="18" alt="" src="images/icons/png/map-pointer-videoid.png" />
              </span>{' '}
              {text}
            </li>
          );
        })}
      {isManualOptionOn && (
        <li className="text-right" onClick={() => onClickManualOption()}>
          <span className="font-weight-bold pt-2">{localizedString('enterManually')} </span>{' '}
          <span>
            <img width="18" alt="" src="images/icons/png/forward-videoid.png" />
          </span>
        </li>
      )}
    </ul>
  );
};

AddressList.propTypes = {
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  isManualOptionOn: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      globalAddressKey: PropTypes.string
    })
  ),
  onSelect: PropTypes.func,
  onClickManualOption: PropTypes.func
};
