import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Liveness from 'liveness-js';
import APIs from '@services/APIs';
import { initAudio, setCookie, getCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import { getVoicePromptInfo } from '@lib/Audio';
import { connect } from 'react-redux';
import {
  Failed,
  Permission,
  Incompatible,
  CameraSettings,
  PermissionIssueRequiresAlternativeFlow
} from './FaceScan.errors';
import { Prepare, Actions, Allow } from '../../components/Contents/FaceScan';
import { Message } from '../../components';

class FaceScan extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func,
    onSelfie: PropTypes.func,
    idType: PropTypes.string,
    tokenId: PropTypes.string,
    location: PropTypes.string,
    appConfig: PropTypes.object
  };

  static defaultProps = {
    onNextStep: () => {},
    idType: ''
  };

  constructor(props, context) {
    super(props, context);

    this.state = this.getInitialState();

    this.videoContainer = null;
    this.audioContainer = null;

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleStartRecord = this.handleStartRecord.bind(this);
    this.handleStartSelfie = this.handleStartSelfie.bind(this);
    this.handleFailed = this.handleFailed.bind(this);
    this.handlePromptError = this.handlePromptError.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
  }

  /**
   * Return the component's initial state
   * @return {Object}
   */
  getInitialState() {
    return {
      step: 0,
      totalSteps: 6,
      isAllowing: false,
      isLoading: false,
      isVerifying: false,
      videoLoaded: false,
      videoId: null,
      error: null,
      message: null,
      audio: null,
      session: null,
      sessionId: null,
      completed: false,
      permissionDenied: false
    };
  }

  componentDidMount() {
    APIs.status('faceScanTips');
  }

  /**
   * Go to the next step
   * @return {Void}
   */
  handleNextStep() {
    const { totalSteps, step } = this.state;
    if (step < totalSteps - 1) {
      this.setState(({ step }) => ({ step: step + 1 }));
    }
  }

  /**
   * Go back to the previous step
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    const { step } = this.state;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      onGoBack();
    }
  }

  handleStartSelfie() {
    const { onSelfie } = this.props;
    this.setState({ error: null });
    onSelfie();
  }

  /**
   * Trigger the events to start recording
   * @return {Void}
   */
  async handleStartRecord() {
    const audio = initAudio(this.audioContainer);
    let { WSS_URL } = process.env;
    const { appConfig = {} } = this.props;

    WSS_URL = appConfig.WSS_URL ? appConfig.WSS_URL : WSS_URL;

    APIs.status('faceCapture');
    this.setState({ isLoading: true, isAllowing: true, audio });

    // eslint-disable-next-line camelcase
    const { status, tx_id, session_id, message } = await APIs.createLivenessSession();
    if (status !== 'success') {
      throw new Error(message);
    }
    // eslint-disable-next-line no-console, camelcase
    console.log({ tx_id, session_id, WSS_URL });

    Liveness.createSession(WSS_URL, {
      videoElement: this.videoContainer,
      auth: {
        // eslint-disable-next-line camelcase
        tx_id,
        // eslint-disable-next-line camelcase
        session_id
      }
    })
      .then(({ sessionId }) => {
        this.setState({
          sessionId,
          isLoading: false
        });
      })
      .catch((error) => {
        console.error('liveness session creation failed', error);
        this.handlePromptError(error);
      });

    Liveness.observer.on('error', (error) => {
      this.handlePromptError(error);
    });

    Liveness.observer.on('opened', () => {
      this.setState((state) => {
        if (state.step === 0) {
          return {
            step: 1,
            isAllowing: false
          };
        }
        return state;
      });
    });
  }

  /**
   * Face verification failed
   * @return {Void}
   */
  handlePromptError(error) {
    this.setState({
      isAllowing: false,
      isLoading: false
    });
    console.error('Error: ', { error });
    if (
      error.message &&
      String(error.message)
        .toLowerCase()
        .match(/permission|allowed/i)
    ) {
      let retry = parseInt(getCookie('retry'), 10);
      if (!retry) {
        retry = 0;
      }

      if (retry < 2) {
        retry += 1;
        setCookie('retry', retry.toString(), 1 / 24);

        const Error = retry > 1 ? CameraSettings : Permission;

        setCookie('_permission', 2, 1);
        this.setState({
          error: {
            component: Error,
            props: {
              buttons: [
                {
                  label: localizedString('back'),
                  variant: 'transparent',
                  onClick: () => {
                    this.setState(this.getInitialState());
                  }
                },
                {
                  label: localizedString('tryAgain'),
                  onClick: () => this.setState(this.getInitialState()),
                  dataTestId: 'btn-try-again'
                }
              ]
            }
          },
          step: 0,
          permissionDenied: true
        });
      } else {
        setCookie('retry', retry.toString(), 1);
        setCookie('_permission', 2, 1);

        this.setState({
          error: {
            component: PermissionIssueRequiresAlternativeFlow,
            props: {
              buttons: [
                {
                  label: localizedString('back'),
                  variant: 'transparent',
                  onClick: () => document.location.reload()
                },
                {
                  label: localizedString(
                    'faceVerification.FLOW_V2_TRY_SOMETHING_ELSE_ACTION_BUTTON'
                  ),
                  onClick: this.handleStartSelfie
                }
              ]
            }
          },
          step: 0,
          permissionDenied: true
        });
      }

      return;
    }

    if (error.message && String(error.message).match(/not supported/i)) {
      this.setState({
        error: {
          component: Incompatible,
          props: {}
        }
      });
      return;
    }

    this.setState({
      error: {
        component: Message,
        props: {
          issue: true,
          title: localizedString('errorOccured'),
          children: localizedString('tryAgainReattempt'),
          buttons: [
            {
              label: localizedString('tryAgain'),
              shadow: true,
              onClick: () => this.setState(this.getInitialState()),
              dataTestId: 'btn-try-again'
            }
          ]
        }
      }
    });
  }

  /**
   * Face verification failed
   * @return {Void}
   */
  handleFailed(lr) {
    const { sessionId, completed, permissionDenied } = this.state;
    if (permissionDenied) return;

    if (completed) {
      return;
    }

    const { onNextStep, onGoBack } = this.props;

    console.error('liveness failed', { sessionId });
    APIs.status('livenessFail');

    let attempt = parseInt(getCookie('retryAttempt'), 10) || 0;

    if (attempt) {
      onNextStep({ sessionId, lr, liveness: false });
      setCookie('retryAttempt', null, -1);
      return;
    }

    const buttons = [
      {
        label: localizedString('back'),
        variant: 'transparent',
        onClick: onGoBack
      },
      {
        label: localizedString('tryAgain'),
        onClick: () => {
          attempt += 1;
          setCookie('retryAttempt', attempt.toString(), 1 / 24);

          this.setState({
            error: null,
            step: 0
          });
          this.handleStartRecord();
        }
      }
    ];
    this.setState({ error: { component: Failed, props: { buttons } } });
    /**
     * Upload face scan video.
     */
    APIs.uploadVideo(
      { id: sessionId, attempt: 1, actions: `Smile, Turn head ${lr ? 'left' : 'right'}` },
      null,
      '/api/v4'
    );
  }

  /**
   * Trigger the events to complete face scan
   * @return {Void}
   */
  handleComplete(lr) {
    const { onNextStep } = this.props;
    const { sessionId } = this.state;
    onNextStep({ sessionId, lr, liveness: true });
  }

  render() {
    const { step, audio, isLoading, isAllowing, session, error, completed } = this.state;
    const { component: Error, props: errorProps } = error || {};

    const voiceInfo = getVoicePromptInfo();

    return (
      <div style={{ backgroundColor: '#000' }}>
        {Error && <Error {...errorProps} />}
        {isAllowing && <Allow />}
        {!Error && !completed && (
          <div>
            {!step && <Prepare onGoBack={this.handleGoBack} onStart={this.handleStartRecord} />}
            {step === 1 && (
              <Actions
                onComplete={this.handleComplete}
                timeout={this.handleFailed}
                loading={isLoading}
                session={session}
                onGoBack={this.handleGoBack}
                audio={audio}
                error={this.handlePromptError}
              />
            )}
          </div>
        )}

        <div className="b-video-container" style={{ zIndex: '-1' }}>
          <video
            style={{ display: !isLoading ? 'block' : 'none' }}
            ref={(ref) => {
              this.videoContainer = ref;
            }}
            width="100%"
            height="100%"
            autoPlay
            muted
            playsInline
          />
        </div>

        <audio
          ref={(ref) => {
            this.audioContainer = ref;
          }}
        >
          {voiceInfo.ogg && <source src={voiceInfo.ogg} type="audio/ogg" />}
          {voiceInfo.mp3 && <source src={voiceInfo.mp3} type="audio/mpeg" />}
        </audio>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(FaceScan);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
